import Navigation from './Navigation/Navigation'
import './App.scss';
import ChatBot from './Shared-Components/Chat-Bot/ChatBot';
import { useState } from 'react';
import CookieConsentBanner from './CookiesConsentBanner';
import GoogleAnalytics from './GoogleAnalytics';


const App = () => {
  const [showConsent, setShowConsent] = useState(true);

  const handleConsentAccept = () => {
    document.cookie = "cookie-consent=true; path=/; max-age=" + 60 * 60 * 24 * 365;
    setShowConsent(false);
  };

  const handleConsentCancel = () => {
    setShowConsent(false);
  };

  return (
    <>
      <div className="cookie-consent">
        {showConsent && (
          <CookieConsentBanner
            onAccept={handleConsentAccept}
            onCancel={handleConsentCancel}
          />
        )}
      </div>
      <GoogleAnalytics />
      <Navigation />
      <ChatBot />
    </>
  );
};

export default App;

import React, { useEffect, useState } from "react";

const GoogleAnalytics = () => {
    const [consentGiven, setConsentGiven] = useState(false);

    useEffect(() => {
        const consent = document.cookie
            .split("; ")
            .find((row) => row.startsWith("cookie-consent="))
            ?.split("=")[1];

        if (consent === "true") {
            setConsentGiven(true);
        }
    }, []);

    const loadGoogleAnalytics = () => {
        const script = document.createElement("script");
        script.src = "https://www.googletagmanager.com/gtag/js?id=GTM-NKBXR5QK";
        script.async = true;
        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "GTM-NKBXR5QK");
        };
        document.head.appendChild(script);
    };

    useEffect(() => {
        if (consentGiven) {
            loadGoogleAnalytics();
        }
    }, [consentGiven]);

    return null;
};

export default GoogleAnalytics;

import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieConsentBanner = ({ onAccept, onCancel }) => {
    return (
        <div className="cookie-banner">
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="cookie-consent"
                onAccept={onAccept}
                enableDeclineButton
                declineButtonText="Cancel"
                onDecline={onCancel}
                expires={365}
            >
                We use cookies to enhance your experience and analyze traffic.
                By clicking 'Accept,' you agree to our &nbsp;
                <a
                    href="https://staticfile.start"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Privacy Policy.
                </a>
                {/* This website uses cookies to ensure you get the best experience on our website. {""} &nbsp;
                <a href="https://staticfile.startup2launch.com/Privacy+Policy.pdf">Learn more</a> */}
            </CookieConsent>
        </div>
    );
};

export default CookieConsentBanner;

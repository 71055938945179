import React, { useRef, useState } from "react";
import "./Programs.scss";
import plansheader from "../../Assets/Plans/Plans header 1.png";
import plansplainheader1 from "../../Assets/Plans/Plans-Plain-Banner.png";
import { Button } from "primereact/button";
import { ScrollTop } from "primereact/scrolltop";
import PlansSelector from "./PlansSelector/PlansSelector";
import PastEvents from "./PastEvents/PastEvents";
import Appointment from "./Appointment/Appointment";
import Header from "../../NewLandingPage/Home/Header/Header.jsx";
import Footer from "../Home/Footer/Footer";
import womenBanner from "../../Assets/Plans/Untitled design (10) 1.png";
import OurProjects from "../AboutUs/OurProjects/OurProjects";
import { useNavigate } from "react-router-dom";

const Programs = () => {
  const [selectedProgram, setSelectedProgram] = useState("student");
  const navigate = useNavigate();
  const footerRef = useRef(null);

  const handleScroll = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };


  return (
    <div className="programs-container">
      <ScrollTop />
      <Header />
      <div className="programs" >
        <span className="women-banner">
          <img src={plansplainheader1} />
        </span>
        <div className="banner-content">
          <h3>Your Path to Success</h3>
          <h2>Explore Our Programs & Services</h2>
          <p>
            Tell us more about yourself and we'll help you
            find the perfect program tailored to your needs.
          </p>
          <div className="button-container">
            <Button onClick={() => navigate("/questionnaire")}>Get Started</Button>
          </div>
        </div>
        <div className="banner-image">
          <img src={plansheader} />
        </div>
        <div className="women-banner-sec">
          <img src={womenBanner} />
        </div>
      </div>

      <PlansSelector
        selectedProgram={selectedProgram}
        setSelectedProgram={setSelectedProgram}
        scrollTo={handleScroll}
      />

      <div className="event-and-projects">
        {selectedProgram === "student" ? <PastEvents /> : <OurProjects />}
      </div>

      <Appointment />
      <Footer ref={footerRef} />
    </div>
  );
};

export default Programs;

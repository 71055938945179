import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import "./PlansSelector.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { width } from "@fortawesome/free-solid-svg-icons/faRobot";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { Dialog } from "primereact/dialog";
import { OverlayPanel } from 'primereact/overlaypanel';
import logo from "../../../Assets/Home Page/Startup2Launch-Logo-White-LB-2024_.png"
import CalendlyComponent from "../../../Shared-Components/Calendly/Calendly";
const GreenDot = () => <span className="green_dot" style={{ height: '10px', width: '10px', backgroundColor: '#4caf50', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }}></span>;

export default function PlansSelector({ selectedProgram, setSelectedProgram, scrollTo }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [showStake, setShowStake] = useState(false)
    // const [showNda, setShowNda] = useState(false)
    // const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    // const [hoveredItem, setHoveredItem] = useState(null);
    const op = useRef(null);

    const studentProgram = [
        {
            sp: "SP-1",
            title: "Introductory Entrepreneurship Workshop",
            best: [
                "Middle and high school students who are new to entrepreneurship.",
                "Those interested in learning the basics of entrepreneurship before committing to a longer program.",
            ],
            color: "#EF5064",
            button: "Contact Us",
            duration: "Duration : 1 day",
            include: [
                {
                    title: "Introduction to Entrepreneurship",
                    content:
                        "Learn the definition, importance, and key characteristics of successful entrepreneurs.",
                },
                {
                    title: "Ideation and Brainstorming",
                    content:
                        "Explore techniques for generating business ideas and problem-solving.",
                },
                {
                    title: "Basics of Business Models",
                    content:
                        "An overview of different business models and how they work.",
                },
                {
                    title: "Interactive Activities",
                    content:
                        " Participate in group discussions, case studies, and practice pitching ideas.",
                },
            ],
            recomented:
                "If you are a student with limited knowledge about entrepreneurship and want to gain a foundational understanding in a short timeframe.",
        },

        {
            sp: "SP-2",
            title: "Advanced Bootcamp with Capstone",
            best: [
                "High school or college students eager to gain hands-on entrepreneurship experience.",
                "Those interested in developing and pitching business ideas or specializing in digital marketing, technology, finance, or social impact.",
            ],
            color: "#F9AB60",
            duration: "Duration : 12 days",
            button: "Contact Us",
            include: [
                {
                    title: "Business Planning & Market Research",
                    content: " Learn market analysis and customer targeting.",
                },
                {
                    title: "Product Development & Prototyping",
                    content: "Create and test a product/service.",
                },
                {
                    title: "Skills Mastery Program",
                    content:
                        " Choose a focus - Digital Marketing, Technology, Financial Management, or Social Impact.",
                },
                {
                    title: "Marketing, Sales & Financial Basics",
                    content: "Branding, sales strategies, and financial literacy.",
                },
                {
                    title: "Real-World Experience",
                    content: "Work on client-based projects or internships.",
                },
                {
                    title: "Capstone Project & Launch",
                    content:
                        " Finalize business models and pitch to judges or prepare for launch.",
                },
            ],
            recomented:
                "If you are a student seeking a comprehensive, practical experience to refine a business idea, gain industry-specific skills, and create a capstone project or launch a startup.",
        },

        {
            sp: "SP-3",
            title: "Idea to Implementation Program",
            best: [
                "Who already have a business idea and are ready to take the next steps to launch it.",
                "Who wants hands-on support, mentorship, and practical experience in building, marketing, and scaling their startup?",
            ],
            color: "#220D50",
            batch: "Confidential Program",
            duration: "Duration : 16 weeks",
            button: "Contact Us",
            include: [
                {
                    title: "Business Model Development",
                    content:
                        "Refine and validate your business idea through expert-led sessions and workshops.",
                },
                {
                    title: "Product Development & Prototyping",
                    content:
                        "Create, test, and refine your product or service with the guidance of experienced mentors.",
                },
                {
                    title: "Marketing & Branding",
                    content:
                        "Develop a marketing strategy and brand identity tailored to your target audience.",
                },
                {
                    title: "Funding & Financial Planning",
                    content:
                        "Learn how to secure funding, manage finances, and create a sustainable business model.",
                },
                {
                    title: "Pitch Development & Presentation",
                    content:
                        " Prepare a professional pitch and present your startup to a panel of experts and potential investors.",
                },
                {
                    title: "Launch Support",
                    content:
                        " Receive guidance on setting up operations, managing customer support, and executing a successful launch.",
                },
            ],
            recomented:
                "If you are a student with a defined business idea who wants comprehensive support to turn it into a real, operational business. Perfect for those ready to commit to building, launching, and scaling their startup.",
        },
    ];

    const enterpreneurProgram = [
        {
            sp: "Starter Plan",
            title: "Ignite Your Business Dream Today",
            best: [
                "Early-stage entrepreneurs or students in the ideation phase.",
                "Those with an idea needing refinement, validation, and a basic launch strategy.",
            ],
            color: "#7B9D50",
            batch: "Confidential Program",
            amount: "1K",
            duration: "Duration: 4 weeks | Equity Stake: 1%",
            include: [
                {
                    title: "Ideation Session",
                    content:
                        "Work with experts to refine and articulate your idea clearly.",
                },
                {
                    title: "Product Market Fit Report",
                    content: "Assess the viability of your idea in the target market?",
                },
                {
                    title: "Preliminary Business Plan",
                    content: "Develop an initial roadmap for your startup.",
                },
                {
                    title: "Home Page Design",
                    content: "Get a basic online presence for your business.",
                },
                {
                    title: "Launch Plan",
                    content: " A step-by-step plan to bring your idea to market",
                },
            ],
            recomented:
                "If you answered that you are at the idea stage or development stage and need initial guidance and support to turn your vision into reality.",
        },

        {
            sp: "Standard Launch Plan",
            title: "Transform Your Vision into Reality",
            best: [
                "Entrepreneurs who have a validated idea and are ready to build and launch their product.",
                "Those who need comprehensive support across business modeling, branding, and marketing.",
            ],
            color: "#4072D2",
            batch: "Confidential Program",
            amount: "25K",
            duration: "Duration: 15 weeks | Equity Stake: 5%",
            include: [
                {
                    title: "All features available in the Starter Plan.",
                },
                {
                    title: "Idea Validation",
                    content:
                        " Ensure your idea is market-ready through validation sessions.",
                },
                {
                    title: "Business Modeling",
                    content: "Develop a detailed business model for long-term growth.",
                },
                {
                    title: " Brand and Identity",
                    content: "Craft a compelling brand strategy and visual identity.",
                },
                {
                    title: "Product Development",
                    content: "Begin building and refining your product or service.",
                },
                {
                    title: "Marketing and Launch",
                    content: "Develop a targeted marketing plan and launch strategy.",
                },
            ],
            recomented:
                "If you indicated that your idea is in the development or early launch stage, and you need structured, end-to-end support to transform your vision into a viable business.",
        },

        {
            sp: "Premium Launch Plan",
            title: "From Groundbreaking to Game-Changing",
            best: [
                "Entrepreneurs ready to fully launch, scale, and optimize their business.",
                "Those looking for an advanced program with extensive support in product development, marketing, and growth planning.",
            ],
            color: "#401998",
            batch: "Confidential Program",
            amount: "25K",
            duration: "Duration: 18 weeks | Equity Stake: 10%",
            include: [
                {
                    title: "All features available in the Standard Launch Plan.",
                },
                {
                    title: "Enhanced Product Development",
                    content: "Deep dive into product design and feature enhancement.",
                },
                {
                    title: "Multi-Channel Marketing",
                    content: "Leverage diverse marketing channels for maximum reach.",
                },
                {
                    title: "Legal and Compliance Advisory",
                    content: "Ensure your business adheres to legal requirements.",
                },
                {
                    title: "Sales Optimization and CRM",
                    content: "Set up customer management systems for growth.",
                },
                {
                    title: "Post-Launch Support",
                    content: " Continued assistance and monitoring post-launch.",
                },
                {
                    title: "Growth Planning",
                    content:
                        "Develop strategies for scaling and expanding your business.",
                },
            ],
            recomented:
                "If you have a growth-stage business or if you are at the early launch stage but need advanced support for product enhancement, compliance, and marketing across multiple channels.",
        },
    ];

    const [selectedArray, setSelectedArray] = useState(studentProgram);

    const handleProgramChange = (e) => {
        setSelectedProgram(e);
        e === "student"
            ? setSelectedArray(studentProgram)
            : setSelectedArray(enterpreneurProgram);
    };

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const pageSettings = {
        backgroundColor: 'ffffff',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: '#F9AB60',
        textColor: '#220D50'
    }

    return (
        <>
            <div className="plan-selector">
                <div className="programs-selector">
                    <div
                        className={`programs-students ${selectedProgram === "student" ? "active" : ""
                            }`}
                        onClick={() => handleProgramChange("student")}
                    >
                        For Students
                    </div>
                    <div
                        className={`programs-enterpreneurs ${selectedProgram === "entrepreneurs" ? "active" : ""
                            }`}
                        onClick={() => handleProgramChange("entrepreneurs")}
                    >
                        {screenWidth > 490 ? "For Professional Entrepreneurs" : "For Professionals"}
                    </div>
                </div>

                <div className="nda-desc">
                    <p>
                        <b>Your Ideas, Fully Protected:</b> <i> All programs include a Non-Disclosure Agreement (NDA) to ensure your concepts remain confidential.
                            {screenWidth >= 1700 && "At Startup2Launch, your innovation is safe with us"}
                        </i>
                    </p>
                </div>


                <div className="display-programs-container" >
                    {selectedArray.map((p) => (
                        <>
                            <div className="program-container">
                                <div className="program-section">

                                    <div className="cap" style={{ backgroundColor: p.color }}>
                                        {p?.batch && <div className="cf-program" onClick={(e) => op.current.toggle(e)}>
                                            <i className=" pi pi-info-circle"></i>
                                            <p>Confidential Program</p>
                                        </div>}
                                    </div>


                                    <div className="p-sec-one">
                                        <h4 className="program-number" style={{ color: p.color }}>{p.sp}</h4>
                                        <h2 className="program-title" style={{ color: p.color }}>{p.title}</h2>
                                    </div>
                                    <div className="p-sec-two">
                                        <h3 style={{ color: p.color }}>Best For :</h3>
                                        <div className={`best-points-container ${selectedProgram === "student" ? "sec-two-student-height" : "sec-two-entrepreneurs-height"}`}>
                                            <ul>
                                                {p.best.map((point, index) => (
                                                    <li key={index}>{point}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="p-pricing">
                                            {p?.button ?
                                                <Button >
                                                    <CalendlyComponent
                                                        className="calendly-button"
                                                        buttonLabel={p.button}
                                                        pageSettings={pageSettings}
                                                        url="https://calendly.com/contactus-s2l/s2l-academy" />
                                                </Button> :
                                                <div className="payment"><h1 className="p-amount">{p.amount} </h1>/USD</div>}
                                            <p>{p.duration}{selectedProgram === "entrepreneurs" && <FontAwesomeIcon className="pi" icon={faExclamation} onClick={() => setShowStake(!showStake)} />}</p>
                                        </div>
                                    </div>

                                    <div className={`p-sec-four ${selectedProgram === "student" ? "sec-four-student-height" : "sec-four-entrepreneurs-height"}`} style={{ backgroundColor: p.color }}>
                                        <h3 >Recommended For :</h3>
                                        <p>{p.recomented}</p>
                                    </div>
                                    <div className="p-sec-three">
                                        <h3 style={{ color: p.color }}>What’s Included:</h3>
                                        <div className={`include-points ${selectedProgram === "student" ? "sec-three-student-height" : "sec-three-entrepreneurs-height"}`} >
                                            {p.include.map((point, index) => (
                                                <div className="include-each-points" >
                                                    <span
                                                        className="pi pi-check"
                                                        style={{ color: p.color }}
                                                    ></span>
                                                    <div className="point-details" key={index}>
                                                        <div className="point-content">
                                                            <b>{point.title}</b>{point?.content && <><b> :</b> {point.content}</>}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>

                                </div>
                            </div >
                        </>
                    ))}
                </div>


                <div className="accordian-programs">
                    <Accordion activeIndex={activeIndex} className="accordian-component">
                        {selectedArray.map((p, index) => (
                            <AccordionTab
                                key={index}
                                header={
                                    <div className="accordion-header">
                                        <div className="header-content">
                                            <span style={{ color: p.color }}>{p.sp + " : " + p.title}</span>
                                            <span
                                                className={`arrow-icon ${activeIndex === index ? 'arrow-up' : 'arrow-down'}`}
                                                onClick={() => toggleAccordion(index)}
                                            >
                                                <i className="pi pi-angle-right"></i>
                                            </span>
                                        </div>
                                    </div>
                                }
                                style={{ backgroundColor: "white" }}
                                onClick={() => toggleAccordion(index)}
                            >
                                <div className="program-container">
                                    <div className="program-section">

                                        <div className="cap" style={{ backgroundColor: p.color }}>

                                            {p?.batch && <div className="cf-program" onClick={(e) => op.current.toggle(e)}>
                                                <i className=" pi pi-info-circle"></i>
                                                <p>Confidential Program</p>
                                            </div>}
                                        </div>

                                        <div className="p-sec-one">
                                            <h4 className="program-number" style={{ color: p.color }}>{p.sp}</h4>
                                            <h2 className="program-title" style={{ color: p.color }}>{p.title}</h2>
                                        </div>
                                        <div className="p-sec-two">
                                            <h3 style={{ color: p.color }}>Best For :</h3>
                                            <div className={`best-points-container `}>
                                                <ul>
                                                    {p.best.map((point, idx) => (
                                                        <li key={idx}>{point}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="p-pricing">
                                                {p?.button ? <Button >
                                                    <CalendlyComponent
                                                        className="calendly-button"
                                                        buttonLabel={p.button}
                                                        pageSettings={pageSettings}
                                                        url="https://calendly.com/contactus-s2l/s2l-academy" />
                                                </Button> : <div className="payment"><h1 className="p-amount">{p.amount}</h1>/USD</div>}
                                                <p>{p.duration}{selectedProgram === "entrepreneurs" && <FontAwesomeIcon className="pi" icon={faExclamation} onClick={() => setShowStake(!showStake)} />}</p>
                                            </div>
                                        </div>
                                        <div className={`p-sec-four`} style={{ backgroundColor: p.color }}>
                                            <h3 >Recommended For :</h3>
                                            <p>{p.recomented}</p>
                                        </div>
                                        <div className="p-sec-three">
                                            <h3 style={{ color: p.color }}>What’s Included:</h3>
                                            <div className={`include-points`}>
                                                {p.include.map((point, idx) => (
                                                    <div className="include-each-points" key={idx}>
                                                        <span className="pi pi-check" style={{ color: p.color }}></span>
                                                        <div className="point-details">
                                                            <div className="point-content">
                                                                <b>{point.title}</b>{point?.content && <><b>:</b> {point.content}</>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </AccordionTab>
                        ))}
                    </Accordion>
                </div>
            </div >

            <Dialog className="stake-dialog"

                header={
                    <div className="dialog-header" >
                        <img src={logo} alt="Startup2Launch Logo" style={{ height: '50px' }} />
                        <h2 style={{ color: 'white' }}>Equity Stake</h2>
                    </div>
                }
                visible={showStake}
                draggable={false}
                style={{ width: '90vw', minHeight: "80vh" }}
                onHide={() => { if (!showStake) return; setShowStake(false); }}>

                <div className="stake-dialog-body">
                    <div className='Our-Philosophy'>
                        <h3>&nbsp;Our Philosophy</h3>
                        <p>Our decision to require an equity stake alongside the cost of our plans is rooted in a deep-seated belief in the potential of your ideas and a commitment to nurturing them into thriving businesses.</p>
                        <p>Here's how this approach encapsulates our positive intent and vision:</p></div>
                    <h3><GreenDot />&nbsp;A Testament to Belief and Shared Destiny</h3>
                    <p>S2L is the inaugural investor in your vision, marking our unwavering belief in your idea's potential. This equity stake symbolizes our vested interest in your success, establishing a foundation for mutual growth. Our investment goes beyond financial implications; it's an investment in confidence, effort, and resources, ensuring that we are as dedicated to realizing your vision as you are.</p>

                    <h3><GreenDot />&nbsp;Empowering Entrepreneurs through Subsidized Partnership</h3>
                    <p>Our equity stake is not merely a financial arrangement but a strategic effort to make our comprehensive services more accessible. This approach allows us to subsidize the cost of bringing your idea to market, ensuring that financial constraints do not hinder innovation. It's our way of leveling the playing field, offering aspiring entrepreneurs the support and resources they need to succeed.</p>

                    <h3><GreenDot />&nbsp;Cultivating Excellence Through Ownership</h3>
                    <p>By taking an equity stake, S2L intertwines its fate with our clients, instilling a profound sense of ownership and responsibility in our team. This shared stake in the outcome drives us to pursue excellence relentlessly, pushing the boundaries of creativity, strategy, and innovation to secure the best possible results for your business.</p>

                    <h3><GreenDot />&nbsp;Beyond a Provider: A Partner for the Long Haul</h3>
                    <p>Our commitment extends beyond the initial launch; it's a pledge for ongoing partnership and support. The equity stake cements our role as long-term allies invested in your continuous growth and adaptation to market changes. We envision a future where S2L and our clients grow and evolve together, facing challenges and seizing opportunities as a unified force.</p>

                    <h3><GreenDot />&nbsp;Fostering a Community of Innovation</h3>
                    <p>S2L's model fosters a dynamic ecosystem of innovative thinkers, where each success story contributes to a larger narrative of entrepreneurial achievement. Our clients become part of a vibrant community, benefiting from shared experiences, insights, and the collective wisdom of fellow trailblazers. This network effect amplifies the impact of our collaboration, driving industry-wide innovation and progress.</p>
                    <p>Together, let's build businesses that succeed and redefine what's possible.</p>
                </div>
            </Dialog>
            <OverlayPanel ref={op} className="overlay-pannel">
                <p>
                    <b>Confidential Program: Protecting Your Vision</b><br /><br /> Protecting Your Vision
                    To ensure the security of your innovative ideas, <b>Startup2Launch</b> requires a Non-Disclosure Agreement (NDA) for programs labeled "<b>
                        Confidential Program</b>."
                </p>

                <p>
                    This agreement safeguards your intellectual property and ensures that your entrepreneurial concepts are treated with the utmost discretion and professionalism.
                </p>
                <p>
                    By signing the NDA, you can confidently share your vision, knowing that Startup2Launch is committed to protecting your ideas while providing the guidance and support you need to bring them to life. Together, we create a secure and collaborative environment where creativity thrives.
                </p>

                <p>Your trust is our priority. Let's build your future with confidence.</p>
            </OverlayPanel>

        </>



    );
}
